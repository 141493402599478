.header {
    display: flex;
    /*justify-content: space-between;*/
    /*align-items: center;*/
    background-color: white;
    margin: 0;
    height: 80px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}
