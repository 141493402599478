::-webkit-scrollbar{
    display: none;
}
.layout{
    /*margin-top: 120px;*/
}
.banner {
    width: 100%;
    height: 300px;
    background: url("../../assets/home/banner.jpg") no-repeat;
    background-size: 100% 100%;
}
.banner-web{
    width: 100%;
    height: 600px;
    background: url("../../assets/home/banner.jpg") no-repeat;
    background-size: 100% 100%;
}
@media (max-width: 1280px){

}

@media (min-width: 1281px) {

}