/*.banner {*/
/*    width: 100%;*/
/*    !*height: 700px;*!*/
/*    background: url("../../assets/list/banner.jpg") no-repeat;*/
/*    background-size: 100% 100%;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    color: white;*/
/*    flex-direction: column;*/
/*}*/

.baner-title {
    font-size: 51px;
}

.baner-sub {
    font-size: 18px;
    margin-top: 15px;
}

.desc-v {
    width: 100%;
    display: flex;
    font-size: 36px;
    color: #272727;
    align-items: center;
    flex-direction: column;
    margin-top: 35px;
}
.desc-v-mobile {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.desc-label {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;

}
.label {
    height: 60px;
    border: 3px solid #979797;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #272727;
    font-size: 20px;
    margin-top: 65px;
}
.label-mobile {
     width: 145px;
     height: 40px;
     border: 2px solid #979797;
     border-radius: 30px;
     text-align: center;
     display: flex;
     justify-content: center;
     align-items: center;
     color: #272727;
     font-size: 15px;
     margin-top: 20px;
 }