.page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
}

.navbar {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 60px;
  margin-right: 80px;
}

.navbar-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.navbar-icon>p{
  color: white;
  padding: 8px;
  font-size: 16px;
}
.icon-btn {
  max-height: 60px;
  margin: 0px 10px;
}

.menu {
  font-family: Microsoft YaHei;
  font-size: 16px;
  padding: 8px 15px;
  cursor: pointer;
  line-height: 73px;
  color: white;
  margin: 0px 5px;
}

.menu:hover {
  background-color: white;
  border-radius: 10px;
}

.top {
  position: relative;
  display: flex;
  justify-content: center;
  padding-bottom: 175px;
}

.usercard {
  max-width: 1200px;
  min-width: 1000px;
  height: 250px;
  padding: 20px 30px 30px 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 4px 4px 20px rgba(0,0,0,.16);
  position: absolute;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.userInfo {
  margin-left: 20px;
  margin-top: 20px;
}

.avatar {
  width: 160px;
  height: 160px;
  border-radius: 50%;;
}

.username {
  font-size: 16px;
  font-family: Microsoft YaHei;
}

.msgleft {
  flex: 1;
  margin-left: 50px;
  margin-top: 50px;
}

.msg {
  font-size: 25px;
  font-weight: bold;
  margin: 0;
}

.introRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tagRow {
  display: flex;
}

.tag1 {
  background-color: #ff967e;
  padding: 5px 18px;
  margin-right: 20px;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tag2 {
  background-color: #ffdad1;
  padding: 5px 18px;
  margin-right: 20px;
  border-radius: 10px;
  font-size: 16px;
  color: #9d574b;
  display: flex;
  justify-content: center;
  align-items: center;
}

.count {
  text-align: right;margin-right: 30px;
  color: #666;
  font-size: 16px;
}

.m-top {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.blogger-card {
  width: 100%;
  height: 67px;
  position: absolute;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bc-user {
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.userinfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.m-name {
  font-size: 14px;
  color: #fff;
  text-shadow: 3px 2px 3px #333;
  font-weight: bold;
}

.m-type {
  color: white;
  background-color: #00aa6c;
  border-radius: 5px;
  padding: 1px 5px;
  font-size: 12px;
}

.user-tag {
  color: #fff;
  font-size: 14px;
  text-shadow: 3px 2px 3px #333;
  font-weight: bold;
  margin-top: 5px;
}

.bc-option {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.link-bar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0);
}

.link-icon {
  width: 42px;
  height: 47px;
  margin-top: -10px;
}

.m-blog-card {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  padding: 15px 5px 10px 10px;
}

.blog-msg {
  font-size: 16px;
  padding: 0px 8px;
  font-weight: bold;
  word-wrap: break-word;
  line-break: anywhere;
}

.blog-count {
  width: 100%;
  padding: 8px 1px;
  font-size: 14px;
  border-bottom: 1px #00aa6c solid;
  color: black;
  display: flex;
  align-items: center;
}

.content {
  width: 100%;
  max-width: 1200px;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  /* border: 1px solid red; */
}

.main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: -25px;
  box-shadow: 4px 4px 20px #00aa6c;
  border-radius: 10px;
  padding-bottom: 20px;
  /* border: 1px solid red; */
}

.cnt-left {
  flex: 1;
  background-color: #fff;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
}

.user-bar {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.msg-avatar {
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.name {
  font-size: 14px;
  color: #00aa6c;
}
.replay{
  font-size: 12px;
  color: #ef523d;
  margin-top: 2px;
  font-weight: bold;
  margin-left: 5px;
}
.level {
  font-size: 12px;
  color: #C5C5C5;
  margin-top: 5px;
  font-weight: bold;
}

.msg-cnt {
  /* width: 100%; */
  /*min-height: 100px;*/
}

.comment-card {
  width: 100%;
  position: relative;
  /* border: 1px solid red; */
}

.comment-item {
  width: 100%;
  border: 1px solid #ededeb;
  padding: 5px;
  margin-top: 9px;
  background-color: #fff;
  font-size: 16px;
  line-height: 28px;
  color: #333333;
  /* border: 1px solid red; */
}

.arrow {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #fafaf8;
  border: 1px solid #ededeb;
  transform: rotate(45deg);
  top: -5px;
  left: 126px;
  border-bottom: 0;
  border-right: 0;
}

.option-bar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0px;
  /* border: 1px solid red; */
}

.cmnt-bar {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* border: 1px solid red; */
  align-items: center;
}

.cmnt-avatar {
  width: 32px;
  height: 32px;
  overflow: hidden;
}

.cmnt-name {
  font-size: 14px;
  color: #111;
  margin-left: 6px;
  font-weight: bold;
  margin-top: 0px;
}

.cmnt-level {
  font-size: 12px;
  color: #ff9d00;
  margin-left: 8px;
  margin-top: 1px;
}

.option-text {
  font-size: 12px;
  color: #999999;
}

.pub-area {
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
/*#00aa6c*/
.textarea {
  /* width: 100%; */
  height: 80px !important;
  resize: none !important;
  margin-top: 10px;
  border: 1px solid #09f;
}

.publish {
  /* padding: 5px 20px; */
  background-color: #ff9d00;
  border: none;
  color: #fff !important;
  cursor: pointer;
  margin-top: 20px;
}

.copyright {
  width: 100%;
  font-family: Microsoft YaHei;
  font-size: 14px;
  line-height: 22px;
  margin-top: 20px;
  text-align: center;
  bottom: 10px;
  text-decoration: none;
}

.cnt-right {
  width: 322px;
  border: 1px #ececec solid;
  padding: 20px 0px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 4px 4px 20px rgba(0,0,0,0.16);
  display: flex;
  flex-direction: column;
  align-items: center;
  /* overflow: hidden; */
  margin-left: 20px;
}

.sidebar-img {
  width: 280px;
  height: 186px;
  margin-top: 20px;
}

.side-title {
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #ff6a3b;
}

.cs-code {
  width: 280px;
}

.rfbtn {
  width: 100%;
  height: 22px;
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #ff6a3b;
}

.weixin {
  color: #ea0008 !important;
  background: #ffffff !important;
  font-weight: bold !important;
}